<template>
    <div v-if="Submitted" id="pop">
    {{ AlertMessage }}
    </div>
    <div id="task">
        <div id="title">
            <h3>Title</h3>
            <input type="text" v-model="title" placeholder="add event title">
        </div>
        <div id="title">
            <h3>Description</h3>
            <textarea name="" id="" cols="30" rows="10" v-model="description"></textarea>
        </div>
        <div id="title">
            <div id="statement">
                <h4>Add subtask</h4>
                <img :src="require('/src/assets/buttons/add-sub-task.png')" alt="subtask" @click="addSubtask">
            </div>
        <div id="subtask" v-if="Subtask1">
            <img :src="require('/src/assets/buttons/minus.png')" alt="minus" style="margin-bottom:-20px;right:100px;" @click="minusOP(1)">
            <h3>Title</h3>
            <input type="text" placeholder="add title" v-model="subVal1">
        </div>
        <div id="subtask" v-if="Subtask2">
            <img :src="require('/src/assets/buttons/minus.png')" alt="minus" style="margin-bottom:-20px;right:100px;" @click="minusOP(2)">
            <h3>Title</h3>
            <input type="text" placeholder="add title" v-model="subVal2">
        </div>
        <div id="subtask" v-if="Subtask3">
            <img :src="require('/src/assets/buttons/minus.png')" alt="minus" style="margin-bottom:-20px;right:100px;" @click="minusOP(3)">
            <h3>Title</h3>
            <input type="text" placeholder="add title" v-model="subVal3">
        </div>
        <div id="subtask" v-if="Subtask4">
            <img :src="require('/src/assets/buttons/minus.png')" alt="minus" style="margin-bottom:-20px;right:100px;" @click="minusOP(4)">
            <h3>Title</h3>
            <input type="text" placeholder="add title" v-model="subVal4">
        </div>
        <div id="subtask" v-if="Subtask5">
            <img :src="require('/src/assets/buttons/minus.png')" alt="minus" style="margin-bottom:-20px;right:100px;" @click="minusOP(5)">
            <h3>Title</h3>
            <input type="text" placeholder="add title" v-model="subVal5">
        </div>
        <div id="subtask" v-if="Subtask6">
            <img :src="require('/src/assets/buttons/minus.png')" alt="minus" style="margin-bottom:-20px;right:100px;" @click="minusOP(6)">
            <h3>Title</h3>
            <input type="text" placeholder="add title" v-model="subVal6">
        </div>
        <div id="subtask" v-if="Subtask7">
            <img :src="require('/src/assets/buttons/minus.png')" alt="minus" style="margin-bottom:-20px;right:100px;" @click="minusOP(7)">
            <h3>Title</h3>
            <input type="text" placeholder="add title" v-model="subVal7">
        </div>
        <div id="subtask" v-if="Subtask8">
            <img :src="require('/src/assets/buttons/minus.png')" alt="minus" style="margin-bottom:-20px;right:100px;" @click="minusOP(8)">
            <h3>Title</h3>
            <input type="text" placeholder="add title" v-model="subVal8">
        </div>
        <div id="subtask" v-if="Subtask9">
            <img :src="require('/src/assets/buttons/minus.png')" alt="minus" style="margin-bottom:-20px;right:100px;" @click="minusOP(9)">
            <h3>Title</h3>
            <input type="text" placeholder="add title" v-model="subVal9">
        </div>
        <div id="subtask" v-if="Subtask10">
            <img :src="require('/src/assets/buttons/minus.png')" alt="minus" style="margin-bottom:-20px;right:100px;" @click="minusOP(10)">
            <h3>Title</h3>
            <input type="text" placeholder="add title" v-model="subVal10">
        </div>
        </div>
        <div id="button" @click="AddTask">Add task</div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            subTask:1,
            title:'',
            description:'',
            alertMessage:'',
            submitted:false,
            subtask1:false,
            subtask2:false,
            subtask3:false,
            subtask4:false,
            subtask5:false,
            subtask6:false,
            subtask7:false,
            subtask8:false,
            subtask9:false,
            subtask10:false,
            subVal1:'',
            subVal2:'',
            subVal3:'',
            subVal4:'',
            subVal5:'',
            subVal6:'',
            subVal7:'',
            subVal8:'',
            subVal9:'',
            subVal10:''
        }
    },
    computed:{
        Subtasks(){
          return this.subTask
        },
        Submitted(){
            return this.submitted;
        },
        Subtask1(){return this.subtask1},
        Subtask2(){return this.subtask2},
        Subtask3(){return this.subtask3},
        Subtask4(){return this.subtask4},
        Subtask5(){return this.subtask5},
        Subtask6(){return this.subtask6},
        Subtask7(){return this.subtask7},
        Subtask8(){return this.subtask8},
        Subtask9(){return this.subtask9},
        Subtask10(){return this.subtask10},
        AlertMessage(){
            return this.alertMessage;
        }
    },
    methods:{
        addSubtask(){
            if(this.subTask > 11) {return; }
            if(this.subTask==1){this.subtask1=true;}
            if(this.subTask==2){this.subtask2=true;}
            if(this.subTask==3){this.subtask3=true;}
            if(this.subTask==4){this.subtask4=true;}
            if(this.subTask==5){this.subtask5=true;}
            if(this.subTask==6){this.subtask6=true;}
            if(this.subTask==7){this.subtask7=true;}
            if(this.subTask==8){this.subtask8=true;}
            if(this.subTask==9){this.subtask9=true;}
            if(this.subTask==10){this.subtask10=true;}
            if(this.subTask==11){
                const now = Date()
                let date = now.toString().split(' ')
                date = date[0]+ ' ' + date[1] + ' ' + date[2]
                if(!localStorage.getItem('notifications')){
                    let msg = `you tried to micromanage for ${this.title}`
                    localStorage.setItem('notifications',JSON.stringify([{'msg':msg,'date':date,'type':'micro'}]))
                }
                else{
                    let msg = `you tried to micromanage for ${this.title}`
                    let notif =JSON.parse(localStorage.getItem('notifications'))
                    notif.push({'msg':msg,'date':date,'type':'micro'})
                    localStorage.setItem('notifications',JSON.stringify(notif))
                }
            }
            
            this.subTask=this.subTask+1;
            

        },
        minusOP(val){
            if(val==1){this.subtask1=false;}
            if(val==2){this.subtask2=false;}
            if(val==3){this.subtask3=false;}
            if(val==4){this.subtask4=false;}
            if(val==5){this.subtask5=false;}
            if(val==6){this.subtask6=false;}
            if(val==7){this.subtask7=false;}
            if(val==8){this.subtask8=false;}
            if(val==9){this.subtask9=false;}
            if(val==10){this.subtask10=false;}
            this.subTask=this.subTask-1;
            
        },
        AddTask(){
            if(this.title=='' && this.description==''){
                this.alertMessage='Bhak Bsdk!!'
                this.submitted=true
        setTimeout(()=>{this.submitted=false},2000)
        return
            }
             
        let subtasks = []
        if(this.subtask1==true){subtasks.push(this.subVal1);this.subVal1=''}
        if(this.subtask2==true){subtasks.push(this.subVal2);this.subVal2=''}
        if(this.subtask3==true){subtasks.push(this.subVal3);this.subVal3=''}
        if(this.subtask4==true){subtasks.push(this.subVal4);this.subVal4=''}
        if(this.subtask5==true){subtasks.push(this.subVal5);this.subVal5=''}
        if(this.subtask6==true){subtasks.push(this.subVal6);this.subVal6=''}
        if(this.subtask7==true){subtasks.push(this.subVal7);this.subVal7=''}
        if(this.subtask8==true){subtasks.push(this.subVal8);this.subVal8=''}
        if(this.subtask9==true){subtasks.push(this.subVal9);this.subVal9=''}
        if(this.subtask10==true){subtasks.push(this.subVal10);this.subVal10=''}
        // this is for tomorrow task
        if(this.$store.getters['getCurrPage'] == 1)
        {
            if(!localStorage.getItem('tomorrowTasks')){
                localStorage.setItem('tomorrowTasks',JSON.stringify([{'title':this.title,'description':this.description,'subtask':subtasks}]))
            }
           else{
            let tasks = JSON.parse(localStorage.getItem('tomorrowTasks'))
            tasks.push({'title':this.title,'description':this.description,'subtask':subtasks})
            localStorage.setItem('tomorrowTasks',JSON.stringify(tasks))
           }
        }
        // this is for today task
        if(this.$store.getters['getCurrPage'] == 0)
        {   if(!localStorage.getItem('todTasks'))
               localStorage.setItem('todTasks',1)
               else{
                let d = JSON.parse(localStorage.getItem('todTasks'))
                localStorage.setItem('todTasks',d+1)
               }
            if(!localStorage.getItem('todayTasks')){
                localStorage.setItem('todayTasks',JSON.stringify([{'title':this.title,'description':this.description,'subtask':subtasks}]))
            }
            else{
                let tasks = JSON.parse(localStorage.getItem('todayTasks'))
            tasks.push({'title':this.title,'description':this.description,'subtask':subtasks})
            localStorage.setItem('todayTasks',JSON.stringify(tasks))
            }
        }
        //this for info 1 array :gali
        if(this.$store.getters['getCurrPage'] == 2)
        {   if(!localStorage.getItem('info1-num'))
               localStorage.setItem('info1-num',1)
               else{
                let d = JSON.parse(localStorage.getItem('info1-num'))
                localStorage.setItem('info1-num',d+1)
               }
            if(!localStorage.getItem('info1')){
                localStorage.setItem('info1',JSON.stringify([{'title':this.title,'description':this.description,'subtask':subtasks}]))
            }
            else{
                let tasks = JSON.parse(localStorage.getItem('info1'))
            tasks.push({'title':this.title,'description':this.description,'subtask':subtasks})
            localStorage.setItem('info1',JSON.stringify(tasks))
            }
        }
        //this is for info 2 array :goals
        if(this.$store.getters['getCurrPage'] == 3)
        {   if(!localStorage.getItem('info2-num'))
               localStorage.setItem('indo2-num',1)
               else{
                let d = JSON.parse(localStorage.getItem('info2-num'))
                localStorage.setItem('info2-num',d+1)
               }
            if(!localStorage.getItem('info2')){
                localStorage.setItem('info2',JSON.stringify([{'title':this.title,'description':this.description,'subtask':subtasks}]))
            }
            else{
                let tasks = JSON.parse(localStorage.getItem('info2'))
            tasks.push({'title':this.title,'description':this.description,'subtask':subtasks})
            localStorage.setItem('info2',JSON.stringify(tasks))
            }
        }
        //this is for info 3 array :achievements
        if(this.$store.getters['getCurrPage'] == 4)
        {   if(!localStorage.getItem('info3-num'))
               localStorage.setItem('info3-num',1)
               else{
                let d = JSON.parse(localStorage.getItem('info3-num'))
                localStorage.setItem('info3-num',d+1)
               }
            if(!localStorage.getItem('info3')){
                localStorage.setItem('info3',JSON.stringify([{'title':this.title,'description':this.description,'subtask':subtasks}]))
            }
            else{
                let tasks = JSON.parse(localStorage.getItem('info3'))
            tasks.push({'title':this.title,'description':this.description,'subtask':subtasks})
            localStorage.setItem('info3',JSON.stringify(tasks))
            }
        }
        
        this.title=''
        this.description=''
        this.submitted=true
        this.alertMessage='Task added'
        setTimeout(()=>{this.submitted=false},2000)
        }
    },
    mounted(){
        this.subTask=1;
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Sofia');
#task{
    width:100vw;
    padding-left:5vw;
    padding-right:5vw;
    height:fit-content;
    margin-top:10px;
    margin-bottom:10px;
}
#title{
    width:90vw;
    padding-left:4vw;
    height: fit-content;
}
#title h3{
    font-family:'Roboto';
    font-size:21px;
    font-weight: 400;
    margin-bottom:5px;
    color:#DCD7C9;
}
#title input{
    outline: none;
    background: #3F4E4F;
    width:80vw;
    height:40px;
    border-radius: 10px;
    padding-left:10px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size:18px;
    line-height: 18px;
    font-weight: 400;
    border:1px solid #3F4E4F;
    color:#DCD7C9;
}
#title textarea{
    width:80vw;
    height:100px;
    outline: none;
    border:1px solid #3F4E4F;
    background: #3F4E4F;
    border-radius: 10px;;
    resize: none;
    padding-left:10px;
    padding-right:10px;
    line-height:18px;
    padding-top:5px;
    padding-bottom:5px;
    font-size:19px;
    color:#DCD7C9;
}
#statement{
    display: flex;
    flex-direction: row;
    margin-bottom:-30px;
}
#statement img{
    width:30px;
    height:30px;
    margin-top:20px;
    margin-left:8px;
}
#statement h4{
    color:#DCD7C9;
}
#subtask{
    margin-left:4vw;
    width:40vw;
    margin-top:4px;
    margin-bottom:4px;
   
}
#subtask h3{
font-weight: 500;
font-size:21px;
color:#DCD7C9;
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
#subtask input{
    width:50vw;
    height:30px;
    outline: none;
    border-radius: 10px;;
    padding-left:10px;
    padding-right:10px;
    line-height:18px;
    padding-top:5px;
    padding-bottom:5px;
}
#button{
width:40vw;
height:40px;
font-size:24px;
font-weight: 500;
font-family:'Sofia';
color:#2C3639;
background: #A27B5C;
text-align: center;
line-height: 40px;
border-radius: 10px;
margin-left:22vw;
margin-top:30px;
}
#pop{
    width:60vw;
    margin-left:18vw;
    height:50px;
    background:#DCD7C9;
    border-radius:20px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.584);
    text-align: center;
    line-height: 50px;
    color:#2C3639;
    font-weight: 450;
    font-size:20px;
animation-name: horizontal-shaking;
animation-duration: 1s;
animation-fill-mode: forwards;
}
@keyframes horizontal-shaking {
    0% { transform: translateX(0) }
    25% { transform: translateX(5px) }
    50% { transform: translateX(-5px) }
    75% { transform: translateX(5px) }
    100% { transform: translateX(0) }
   }
</style>