<template>
    <div id="info1">
        <div v-if="Empty">
            <h4 id="message">Add few embarassing moments that you want to remind yourself when you fail to accomplish your tasks!!"</h4>
        </div>
        <div v-else>
            <info-text v-for="data,index in Moments" :key="index" :title="data.title" :description="data.description" :subtasks="data.subtask" :color="index"></info-text>
        </div>
    </div>
</template>

<script>
import InfoText from './info-text.vue'
export default {
    components:{InfoText},
    data(){
        return{
            empty:false,
            moments:[]
        }
    },
    computed:{
        Empty(){
            return this.empty;
        },
        Moments(){
            return this.moments;
        }
    },
    mounted(){
        this.$store.commit('setCurrPage',{value:2})
        if(!localStorage.getItem('info1') || JSON.parse(localStorage.getItem('info1')) == 0)
        this.empty = true;
        else
        this.moments = JSON.parse(localStorage.getItem('info1'))
    }
}
</script>

<style scoped>
#info1{
    width:100vw;
    height:fit-content;
    overflow-x:hidden;
    padding-bottom:4vh;
}
#message{
    font-family: 'Sofia';
    color:#DCD7C9;
    font-size:21px;
    font-weight: 260;
    text-align:center
}
</style>