<template>
    <div id="task" v-if="Display">
      <div id="col1">
        <div id="line" style="background:rgba(217, 5, 5, 0.7)" v-if="color==0"></div>
         <div id="line" style="background:rgba(4, 76, 219, 0.7)" v-if="color==1"></div>
         <div id="line" style="background:rgba(4, 228, 68, 0.7)" v-if="color==2"></div>
         <div id="line" style="background:rgba(5, 43, 232, 0.7)" v-if="color==3"></div>
         <div id="line" style="background:rgba(135, 9, 232, 0.7)" v-if="color==4"></div>
         <div id="line" style="background:rgba(211, 12, 229, 0.7)" v-if="color==5"></div>
         <div id="line" style="background:rgba(228, 7, 74, 0.7)" v-if="color==6"></div>
         <div id="line" style="background:rgba(24, 228, 5, 0.7)" v-if="color==7"></div>
         <div id="line" style="background:rgba(54, 9, 238, 0.7)" v-if="color==8"></div>
         <div id="line" style="background:rgba(236, 8, 8, 0.7)" v-if="color==9"></div>
         <div id="line" style="background:rgba(92, 4, 233, 0.7)" v-if="color==10"></div>
         <div id="line" style="background:rgba(207, 5, 238, 0.7)" v-if="color==11"></div>
         <div id="line" style="background:rgba(218, 4, 4, 0.7)" v-if="color==12"></div>
         <div id="line" style="background:rgba(8, 235, 193, 0.7)" v-if="color==13"></div>
         <div id="line" style="background:rgba(64, 3, 233, 0.7)" v-if="color==14"></div>
         <div id="line" style="background:rgba(227, 5, 5, 0.7)" v-if="color==15"></div>
         <div id="line" style="background:rgba(105, 3, 238, 0.7)" v-if="color==16"></div>
         <div id="line" style="background:rgba(223, 6, 201, 0.7)" v-if="color==17"></div>
         <div id="line" style="background:rgba(218, 238, 7, 0.7)" v-if="color==18"></div>
         <div id="line" style="background:rgba(228, 4, 71, 0.7)" v-if="color==19"></div>
         <div id="line" style="background:rgba(235, 144, 8, 0.70)" v-if="color==20"></div>
         <div id="line" style="background:rgba(53, 227, 5, 0.7)" v-if="color==21"></div>
         <div id="line" style="background:rgba(8, 109, 224, 0.7)" v-if="color==22"></div>
         <div id="line" style="background:rgba(233, 75, 8, 0.7)" v-if="color==23"></div>
         <div id="line" style="background:rgba(80, 6, 240, 0.7)" v-if="color==24"></div>
         <div id="line" style="background:rgba(213, 10, 231, 0.7)" v-if="color==25"></div>
         <div id="line" style="background:rgba(236, 229, 7, 0.7)" v-if="color==26"></div>
         <div id="line" style="background:rgba(7, 231, 29, 0.7)" v-if="color==27"></div>
         <div id="line" style="background:rgba(235, 7, 132, 0.7)" v-if="color==28"></div>
         <div id="line" style="background:rgba(8, 55, 223, 0.7)" v-if="color==29"></div>
         <div id="line" style="background:rgba(222, 135, 5, 0.7)" v-if="color==30"></div>
         <div id="line" style="background:rgba(201, 3, 3, 0.7)" v-if="color==31"></div>
         <div id="line" style="background:rgba(216, 4, 209, 0.7)" v-if="color==32"></div>
         <div id="line" style="background:rgba(169, 224, 5, 0.7)" v-if="color==33"></div>
         <div id="line" style="background:rgba(236, 7, 91, 0.7)" v-if="color==34"></div>
         <div id="line" style="background:rgba(7, 228, 18, 0.7)" v-if="color==35"></div>
         <div id="line" style="background:rgba(96, 7, 239, 0.7)" v-if="color==36"></div>
         <div id="line" style="background:rgba(234, 127, 4, 0.7)" v-if="color==37"></div>
         <div id="line" style="background: rgba(217, 9, 58, 0.7)" v-if="color==38"></div>
         <div id="line" style="background:rgba(179, 9, 209, 0.7)" v-if="color==39"></div>
         <div id="line" style="background:rgba(215, 230, 7, 0.7)" v-if="color==40"></div>
         <div id="line" style="background:rgba(236, 187, 8, 0.7)" v-if="color==41"></div>
         <div id="line" style="background:rgba(215, 6, 118, 0.7)" v-if="color==42"></div>
         <div id="line" style="background:rgba(6, 227, 201, 0.7)" v-if="color==43"></div>
         <div id="line" style="background:rgba(236, 34, 7, 0.7)" v-if="color==44"></div>
         <div id="line" style="background:rgba(8, 180, 232, 0.7)" v-if="color==45"></div>
         <div id="line" style="background:rgba(243, 24, 104, 0.7)" v-if="color==46"></div>
         <div id="line" style="background:rgba(148, 6, 235, 0.7)" v-if="color==47"></div>
         <div id="line" style="background:rgba(151, 11, 238, 0.7)" v-if="color==48"></div>
      </div>
      <div id="col2">
        <img :src="require('/src/assets/buttons/minus.png')" alt="remove" id="minus" @click="remove">
           <h2>{{ title }}</h2>
           <h3>{{ description }}</h3>
           <ul v-if="subtasks.length>0">
            <li v-for="subtask,index in subtasks" :key="index">{{ subtask }}</li>
           </ul>
      </div>
    </div>
</template>

<script>
export default {
props:['color','title','description','subtasks'],
data(){
    return{
        display:true
    }
},
computed:{
    Display(){
        return this.display
    }
},
methods:{
    remove(){
        this.display=false;
        //code to remove task
        let tasks = JSON.parse(localStorage.getItem('tomorrowTasks'))
        let newTasks =[]  
        for(let i=0;i<tasks.length;i++){
              if(tasks[i].title != this.title){
                newTasks.push(tasks[i])
              }
          }
          localStorage.setItem('tomorrowTasks',JSON.stringify(newTasks))
    }
}
}
</script>

<style scoped>
#task{
    width:98vw;
    background:#3F4E4F;
    display: grid;
    grid-template-columns: 15vw 85vw;
    margin-top:2.5px;
    margin-bottom:2.5px;
    margin-left:1vw;
}
#col1{
    width:15vw;
}
#line{
    width:3px;
    height: 100%;
    margin-left:10vw;
}
#col2{
    width:85vw;
}
#minus{
    float: right;
    margin-right:5vw;
    margin-top:10px;
}
h2{
    color:#A27B5C
}
h3,li{
    color:#DCD7C9;
}
</style>