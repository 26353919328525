<template>
    <div id="thirdSec" v-if="Logs.length>0">
        <h2 style="margin-top:10px;">Your record so far</h2>
        <div id="records">
            <div class="record" v-for="log,index in Logs" :key="index">
                <green-circle v-if="log.percent == 100"></green-circle>
                <red-circle v-else :percent="log.percent"></red-circle>
                <h4>{{ log.date }}</h4>
            </div>
        </div>
    </div>
    <div v-else id="else">
        <h3>No record shame!!</h3>
    </div>
</template>

<script>
import GreenCircle from './green-circle.vue'
import RedCircle from './red-circle.vue'
export default {
    components:{GreenCircle,RedCircle},
    data(){
        return{
            logs:[]
        }
    },
    computed:{
        Logs(){
            return this.logs;
        }
    },
    mounted(){
        if(!localStorage.getItem('logs')|| JSON.parse(localStorage.getItem('logs').length==0)) this.logs =[]
        this.logs = JSON.parse(localStorage.getItem('logs'))
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Sofia');
#thirdSec{
    width:100vw;
    background: #DCD7C9;
    margin-top:2vh;
    margin-bottom:10vh;
}
#thirdSec h2{
    font-family: 'Roboto';
    color:#2C3639;
    font-size:20px;
    font-weight: 400;
    margin-left:8px;
}
#records{
    margin-top:5px;
    display: grid;
    padding-left:5vw;
    padding-right:5vw;
    display: grid;
    grid-template-columns:repeat(5,18vw) ;
}
.record h4{
    margin-top:3px;
    margin-left:2px;
    font-family: 'Roboto';
    font-size:18px;
    font-weight: 350;
    color:#3F4E4F;
}
#else{
    width:100vw;
    height:30vh;
    background: #DCD7C9;
    margin-top:10px;
}
#else h3{
    font-family: 'Sofia';
    color:#2C3639;
    font-size:25px;
    text-align: center;
    margin-top:20px;
}
</style>