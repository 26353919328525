import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import WelcomePage from '../views/opening_page.vue'
import AAj from '../views/aaj-page.vue'
import Kal from '../views/kal-page.vue'
import Form from '../views/form-page.vue'
import GaliPage from '../views/gali-page.vue'
import PyarPage from '../views/pyaar-page.vue'
import ReminderPage from '../views/reminders-page.vue'
import NotficationsPage from '../views/notification-page.vue'
import ItihasPage from '../views/itihas-page.vue'
import InfoPage from '../views/info-page.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'welcome',
    component:WelcomePage
  },
  {
    path:'/aaj',
    name:'aaj',
    component:AAj
  },
  {
    path:'/kal',
    name:'kal',
    component:Kal
  },
  {
    path:'/form',
    name:'form',
    component:Form
  },
  {
    path:'/notifications',
    name:"notifications",
    component:NotficationsPage
  },
  {
    path:'/itihas',
    name:'itihas',
    component:ItihasPage
  },
  {
    path:'/info',
    name:'info',
    component:InfoPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
