<template>
    <div id="form">
        <div id="intro">
            <img :src="require('../assets/cappy-medium.png')" alt="cappybara" @click="back">
            <h1>Yeah boii!! start hustling</h1>
         </div>
         <div id="info">
             <h1>{{ date }}</h1>
             <img :src="require('../assets/buttons/add-task.png')" alt="add" @click="addTask(num+1)">
         </div>

         <div id="data">
<div v-if="Task1">
    <img :src="require('../assets/buttons/minus.png')" alt="minus" @click="removeTask(1)" style="margin-left:5vw;margin-top:10px;margin-bottom:-20px">
    <task-comp></task-comp>
    <div id="seprator" style="background:rgba(0,0,0,.55);width:90vw;height:1px;margin-left:5vw;margin-top:15px;margin-bottom:15px"></div>
</div>
<div v-if="Task2">
    <img :src="require('../assets/buttons/minus.png')" alt="minus" @click="removeTask(2)" style="margin-left:5vw;margin-top:10px;margin-bottom:-20px">
    <task-comp></task-comp>
    <div id="seprator" style="background:rgba(0,0,0,.55);width:90vw;height:1px;margin-left:5vw;margin-top:15px;margin-bottom:15px"></div>
</div>
<div v-if="Task3">
    <img :src="require('../assets/buttons/minus.png')" alt="minus" @click="removeTask(3)" style="margin-left:5vw;margin-top:10px;margin-bottom:-20px">
    <task-comp></task-comp>
    <div id="seprator" style="background:rgba(0,0,0,.55);width:90vw;height:1px;margin-left:5vw;margin-top:15px;margin-bottom:15px"></div>
</div>
<div v-if="Task4">
    <img :src="require('../assets/buttons/minus.png')" alt="minus" @click="removeTask(4)" style="margin-left:5vw;margin-top:10px;margin-bottom:-20px">
    <task-comp></task-comp>
    <div id="seprator" style="background:rgba(0,0,0,.55);width:90vw;height:1px;margin-left:5vw;margin-top:15px;margin-bottom:15px"></div>
</div>
<div v-if="Task5">
    <img :src="require('../assets/buttons/minus.png')" alt="minus" @click="removeTask(5)" style="margin-left:5vw;margin-top:10px;margin-bottom:-20px">
    <task-comp></task-comp>
    <div id="seprator" style="background:rgba(0,0,0,.55);width:90vw;height:1px;margin-left:5vw;margin-top:15px;margin-bottom:15px"></div>
</div>
<div v-if="Task6">
    <img :src="require('../assets/buttons/minus.png')" alt="minus" @click="removeTask(6)" style="margin-left:5vw;margin-top:10px;margin-bottom:-20px">
    <task-comp></task-comp>
    <div id="seprator" style="background:rgba(0,0,0,.55);width:90vw;height:1px;margin-left:5vw;margin-top:15px;margin-bottom:15px"></div>
</div>
<div v-if="Task7">
    <img :src="require('../assets/buttons/minus.png')" alt="minus" @click="removeTask(7)" style="margin-left:5vw;margin-top:10px;margin-bottom:-20px">
    <task-comp></task-comp>
    <div id="seprator" style="background:rgba(0,0,0,.55);width:90vw;height:1px;margin-left:5vw;margin-top:15px;margin-bottom:15px"></div>
</div>
<div v-if="Task8">
    <img :src="require('../assets/buttons/minus.png')" alt="minus" @click="removeTask(8)" style="margin-left:5vw;margin-top:10px;margin-bottom:-20px">
    <task-comp></task-comp>
    <div id="seprator" style="background:rgba(0,0,0,.55);width:90vw;height:1px;margin-left:5vw;margin-top:15px;margin-bottom:15px"></div>
</div>
<div v-if="Task9">
    <img :src="require('../assets/buttons/minus.png')" alt="minus" @click="removeTask(9)" style="margin-left:5vw;margin-top:10px;margin-bottom:-20px">
    <task-comp></task-comp>
    <div id="seprator" style="background:rgba(0,0,0,.55);width:90vw;height:1px;margin-left:5vw;margin-top:15px;margin-bottom:15px"></div>
</div>
<div v-if="Task10">
    <img :src="require('../assets/buttons/minus.png')" alt="minus" @click="removeTask(10)" style="margin-left:5vw;margin-top:10px;margin-bottom:-20px">
    <task-comp></task-comp>
    <div id="seprator" style="background:rgba(0,0,0,.55);width:90vw;height:1px;margin-left:5vw;margin-top:15px;margin-bottom:15px"></div>
</div>
<div v-if="Task11">
    <img :src="require('../assets/buttons/minus.png')" alt="minus" @click="removeTask(11)" style="margin-left:5vw;margin-top:10px;margin-bottom:-20px">
    <task-comp></task-comp>
    <div id="seprator" style="background:rgba(0,0,0,.55);width:90vw;height:1px;margin-left:5vw;margin-top:15px;margin-bottom:15px"></div>
</div>
<div v-if="Task12">
    <img :src="require('../assets/buttons/minus.png')" alt="minus" @click="removeTask(12)" style="margin-left:5vw;margin-top:10px;margin-bottom:-20px">
    <task-comp></task-comp>
    <div id="seprator" style="background:rgba(0,0,0,.55);width:90vw;height:1px;margin-left:5vw;margin-top:15px;margin-bottom:15px"></div>
</div>
<div v-if="Task13">
    <img :src="require('../assets/buttons/minus.png')" alt="minus" @click="removeTask(13)" style="margin-left:5vw;margin-top:10px;margin-bottom:-20px">
    <task-comp></task-comp>
    <div id="seprator" style="background:rgba(0,0,0,.55);width:90vw;height:1px;margin-left:5vw;margin-top:15px;margin-bottom:15px"></div>
</div>
<div v-if="Task14">
    <img :src="require('../assets/buttons/minus.png')" alt="minus" @click="removeTask(14)" style="margin-left:5vw;margin-top:10px;margin-bottom:-20px">
    <task-comp></task-comp>
    <div id="seprator" style="background:rgba(0,0,0,.55);width:90vw;height:1px;margin-left:5vw;margin-top:15px;margin-bottom:15px"></div>
</div>
<div v-if="Task15">
    <img :src="require('../assets/buttons/minus.png')" alt="minus" @click="removeTask(15)" style="margin-left:5vw;margin-top:10px;margin-bottom:-20px">
    <task-comp></task-comp>
    <div id="seprator" style="background:rgba(0,0,0,.55);width:90vw;height:1px;margin-left:5vw;margin-top:15px;margin-bottom:15px"></div>
</div>
<div v-if="Task16">
    <img :src="require('../assets/buttons/minus.png')" alt="minus" @click="removeTask(16)" style="margin-left:5vw;margin-top:10px;margin-bottom:-20px">
    <task-comp></task-comp>
    <div id="seprator" style="background:rgba(0,0,0,.55);width:90vw;height:1px;margin-left:5vw;margin-top:15px;margin-bottom:15px"></div>
</div>
<div v-if="Task17">
    <img :src="require('../assets/buttons/minus.png')" alt="minus" @click="removeTask(17)" style="margin-left:5vw;margin-top:10px;margin-bottom:-20px">
    <task-comp></task-comp>
    <div id="seprator" style="background:rgba(0,0,0,.55);width:90vw;height:1px;margin-left:5vw;margin-top:15px;margin-bottom:15px" ></div>
</div>
<div v-if="Task18">
    <img :src="require('../assets/buttons/minus.png')" alt="minus" @click="removeTask(18)" style="margin-left:5vw;margin-top:10px;margin-bottom:-20px">
    <task-comp></task-comp>
    <div id="seprator" style="background:rgba(0,0,0,.55);width:90vw;height:1px;margin-left:5vw;margin-top:15px;margin-bottom:15px"></div>
</div>
<div v-if="Task19">
    <img :src="require('../assets/buttons/minus.png')" alt="minus" @click="removeTask(19)" style="margin-left:5vw;margin-top:10px;margin-bottom:-20px">
    <task-comp></task-comp>
    <div id="seprator" style="background:rgba(0,0,0,.55);width:90vw;height:1px;margin-left:5vw;margin-top:15px;margin-bottom:15px"></div>
</div>
<div v-if="Task20">
    <img :src="require('../assets/buttons/minus.png')" alt="minus" @click="removeTask(20)" style="margin-left:5vw;margin-top:10px;margin-bottom:-20px">
    <task-comp></task-comp>
    <div id="seprator" style="background:rgba(0,0,0,.55);width:90vw;height:1px;margin-left:5vw;margin-top:15px;margin-bottom:15px"></div>
</div>
<div v-if="Task21">
    <img :src="require('../assets/buttons/minus.png')" alt="minus" @click="removeTask(21)" style="margin-left:5vw;margin-top:10px;margin-bottom:-20px">
    <task-comp></task-comp>
    <div id="seprator" style="background:rgba(0,0,0,.55);width:90vw;height:1px;margin-left:5vw;margin-top:15px;margin-bottom:15px"></div>
</div>
         </div>
    </div>
   
</template>

<script>
import TaskComp from '../components/tasks/task-comp.vue'
export default {
    components:{TaskComp},
    data(){
        return {
            num:1,
            task1:true,
            task2:false,
            task3:false,
            task4:false,
            task5:false,
            task6:false,
            task7:false,
            task8:false,
            task9:false,
            task10:false,
            task11:false,
            task12:false,
            task13:false,
            task14:false,
            task15:false,
            task16:false,
            task17:false,
            task18:false,
            task19:false,
            task20:false,
            task21:false,
        }
    },
    computed:{
       count(){
        return this.number;
       },
        date(){
            const now = new Date()
            let day = now.toUTCString().split(',')[0].toString()
            if(day=='Mon'){ return "Monday" }
            if(day=='Tue'){ return "Tuesday" }  
            if(day=='Wed'){ return "Wednesay" }  
            if(day=='Thu'){ return "Thursday" }  
            if(day=='Fri'){ return "Friday" }  
            if(day=='Sat'){ return "Saturday" }  
            if(day=='Sun'){ return "Sunday" }    
            else{return 'Thursday'}   
        },
        Task1(){return this.task1},
        Task2(){return this.task2},
        Task3(){return this.task3},
        Task4(){return this.task4},
        Task5(){return this.task5},
        Task6(){return this.task6},
        Task7(){return this.task7},
        Task8(){return this.task8},
        Task9(){return this.task9},
        Task10(){return this.task10},
        Task11(){return this.task11},
        Task12(){return this.task12},
        Task13(){return this.task13},
        Task14(){return this.task14},
        Task15(){return this.task15},
        Task16(){return this.task16},
        Task17(){return this.task17},
        Task18(){return this.task18},
        Task19(){return this.task19},
        Task20(){return this.task20},
        Task21(){return this.task21}
    },
    methods:{
        addTask(num){
            this.num=num;
            if(num>21) return
            if(num==21){
                const now = Date()
                let date = now.toString().split(' ')
                date = date[0]+ ' ' + date[1] + ' ' + date[2]
                if(!localStorage.getItem('notifications')){
                    localStorage.setItem('notifications',JSON.stringify([{'msg':'you tried to overplan your day,stop planning this much','date':date,'type':'overplan'}]))
                }
                else{
                    let notif =JSON.parse(localStorage.getItem('notifications'))
                    notif.push({'msg':'you over planned','date':date,'type':'overplan'})
                    localStorage.setItem('notifications',JSON.stringify(notif))
                }
                return
            }
            if(num>0){this.task1=true;}
            if(num>1){this.task2=true;}
            if(num>2){this.task3=true;}
            if(num>3){this.task4=true;}
            if(num>4){this.task5=true;}
            if(num>5){this.task6=true;}
            if(num>6){this.task7=true;}
            if(num>7){this.task8=true;}
            if(num>8){this.task9=true;}
            if(num>9){this.task10=true;}
            if(num>10){this.task11=true;}
            if(num>11){this.task12=true;}
            if(num>12){this.task13=true;}
            if(num>13){this.task14=true;}
            if(num>14){this.task15=true;}
            if(num>15){this.task16=true;}
            if(num>16){this.task17=true;}
            if(num>17){this.task18=true;}
            if(num>18){this.task19=true;}
            if(num>19){this.task20=true;}
            if(num>20){this.task21=true;}
            window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth', // Optional: Enables smooth scrolling animation
      });
        },
        removeTask(num){
            if(num==1){this.task1=false;}
            if(num==2){this.task2=false;}
            if(num==3){this.task3=false;}
            if(num==4){this.task4=false;}
            if(num==5){this.task5=false;}
            if(num==6){this.task6=false;}
            if(num==7){this.task7=false;}
            if(num==8){this.task8=false;}
            if(num==9){this.task9=false;}
            if(num==10){this.task10=false;}
            if(num==11){this.task11=false;}
            if(num==12){this.task12=false;}
            if(num==13){this.task13=false;}
            if(num==14){this.task14=false;}
            if(num==15){this.task15=false;}
            if(num==16){this.task16=false;}
            if(num==17){this.task17=false;}
            if(num==18){this.task18=false;}
            if(num==19){this.task19=false;}
            if(num==20){this.task20=false;}
            if(num==21){this.task21=false;}
        },
        back(){
            this.$router.back();
        }
    },
}
</script>

<style scoped>
#form{
    top:0px;
    left:0px;
    width:100vw;
    height: fit-content;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    background: #2C3639;
}
#form::-webkit-scrollbar{
    display: none;
}
#intro{
    display: flex;
    flex-direction: row;
 }
 #intro img{
 width:80px;
 height:70px;
 margin-top:14px;
 margin-left:10px;
 }
 #intro h1{
    margin-left:4px;
    font-size:22px;
    color:rgba(0, 0, 0, 0.804);
    font-weight:400;
    font-family:'Imprint MT Shadow';
    margin-top:14px;
 }
 #info h1{
     float:left;
     margin-left:30px;
     font-size:21px;
     color:rgba(0, 0, 0, 0.808);
     font-weight: 400;
     font-family: Verdana, Geneva, Tahoma, sans-serif;
 }
#add{
    padding-top:35px;
    display: flex;
    flex-direction:row ;
}
#add h3{
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
font-weight: 500;
margin-left:-65px;
font-size:18px;
}
#add img{
    margin-left:10px;
    width:40px;
    height:40px;
    margin-top:15px;
}
/*intro comp styling*/
#intro{
    display: flex;
    flex-direction: row;
 }
 #intro img{
 width:80px;
 height:70px;
 margin-top:14px;
 margin-left:10px;
 }
 #intro h1{
 margin-left:4px;
 font-size:22px;
 color:#DCD7C9;
 font-weight:400;
 font-family:'Sofia';
 margin-top:14px;
 }
 #info h1{
     float:left;
     margin-left:30px;
     font-size:21px;
     color:#DCD7C9;
     font-weight: 400;
     font-family:'Sofia';
     margin-top:-2px;
 }
 #info{
     display: flex;
     flex-direction: row;
 }
 #info img{
 margin-left:83vw;
 bottom:20vw;
 position:fixed;
 }
</style>