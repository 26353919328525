<template>
    <transition name="fade">
        <router-view></router-view>
      </transition>
</template>

<script>

export default ({
   mounted(){
    if(!localStorage.getItem('tomTasks')){
    localStorage.setItem('tomTasks',0)
    }
    if(!localStorage.getItem('todTasks')){
    localStorage.setItem('todTasks',0)
    }
    if(!localStorage.getItem('form')){
    localStorage.setItem('form',false)
    }
    if(!localStorage.getItem('loggedDays'))
    localStorage.setItem('loggedDays',0)
    if(!localStorage.getItem('winDays'))
    localStorage.setItem('winDays',0)
    if(!localStorage.getItem('streak'))
    localStorage.setItem('streak','0')
    if(!localStorage.getItem('mon'))
    localStorage.setItem('mon',0)
    if(!localStorage.getItem('tue'))
    localStorage.setItem('tue',0)
    if(!localStorage.getItem('wed'))
    localStorage.setItem('wed',0)
    if(!localStorage.getItem('thu'))
    localStorage.setItem('thu',0)
    if(!localStorage.getItem('fri'))
    localStorage.setItem('fri',0)
    if(!localStorage.getItem('sat'))
    localStorage.setItem('sat',0)
    if(!localStorage.getItem('sun'))
    localStorage.setItem('sun',0)
    if(!localStorage.getItem('logs'))
    localStorage.setItem('logs',JSON.stringify([]))
    localStorage.setItem('currTask',1)
    localStorage.setItem('kan','aaj')
    localStorage.setItem('nav','aaj')
    localStorage.setItem('active','home')
    localStorage.setItem('pop',0)
   } 
})
</script>

<style scoped>
</style>