<template>
    <div id="free" v-if="todTask">
        <h2> Kya baat bhai bahut free hai {{ kab }} Ye sab kon karega phir ?</h2>
         <ul v-if="goals.length>0">
             <li v-for="goal,index in goals" :key="index">{{ goal.title }}</li>
         </ul>
         <div v-else id="msg">
            <h4>Add some goals that you want accomplish in the goals section they will remind you to keep grinding if you are free</h4>
         </div>
     </div>
</template>

<script>
export default {
    computed:{
        todTask(){
            return localStorage.getItem('todTasks')
        },
        kab(){
            return localStorage.getItem('kab')
        },
        goals(){
            if(!localStorage.getItem('goals'))
            return []
            else{
                let Goals = JSON.parse(localStorage.getItem('goals'))
                return Goals
            }
        }
    }    
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Sofia');
#free{
    margin-top:10vh;
    width:90vw;
margin-left:5vw;
    }
#free h2{
    font-weight: 450;
    font-size:24px;
    font-family: 'Sofia';
    text-align: center;
    color:#DCD7C9;
    text-align:center
}
#free ul{
    width:100vw;
    padding-left:4vw;
    padding-right:4vw;
    font-family: 'Sofia';
    text-align:center
}
#free ul li{
    margin-left:5vw;
    font-size:21px;
    font-weight: 400;
    font-family: 'Sofia';
   margin-top:10px;
   margin-bottom:10px;
   color:#DCD7C9;
   text-align:center
}
#msg{
    width:90vw;
    margin-left:0vw;
}
#msg h4{
    text-align: center;
    font-size: 20px;
    color:#DCD7C9;
    font-family: 'Sofia';
    font-weight:500;
}
</style>