import { createStore } from 'vuex'
// Define state interface
interface state {
  activePage: number;
  currPage:number;
  welcome:string
}

export default createStore({
  state: {
    activePage:0,
    currPage:0,
    welcome:'Hello abhi lets plan for today'
  },
  getters: {
    getActivePage(state){
      return state.activePage;
    },
    getCurrPage(state){
      return state.currPage;
    },
    getWelcome(state){
      return state.welcome;
    }
  },
  mutations: {
    setActivePage(state,payload){
      state.activePage = payload.value;
    },
    setCurrPage(state,payload){
      state.currPage = payload.value;
    },
    setWelcome(state,payload){
      state.welcome = payload.value;
    }
  },
  actions: {
    
  },
  modules: {
  }
})
