<template>
 <div id="first">
    <div id="col11">
        <div class="info">
            <h3>{{ Days }}</h3>
            <h4>Days logged</h4>
        </div>
        <div class="info">
            <h3>{{ Passed }}</h3>
            <h4>Days were a Win</h4>
        </div>
    </div>
    <div id="col12">
        <circle-progress :percent="Percent" :is-gradient="true" id="prog1"
        :gradient="{
          angle: 90,
          startColor: '#2C3639',
          stopColor: '#A27B5C'
      }"
      :size="145"
      :border-width="10"
      :border-bg-width="10"
      />
      <div id="num11">
       <h3 id="day1"> {{ Passed }} </h3>
       <h3 id="mid"> OF {{ Days}}</h3>
      </div>
      <h3 style="margin-top:2px;font-weight:450;color:#2C3639">Days conquered!!</h3>
    </div> 
 </div>
</template>
  

  <script>
  
  import "vue3-circle-progress/dist/circle-progress.css";
  import CircleProgress from "vue3-circle-progress";
  export default {
    components: {CircleProgress},
    data(){
        return {
            days:0,
            passed:0
        }
    },
    computed:{
        Days(){return this.days},
        Passed(){return this.passed},
        Percent(){
            return (this.passed/this.days)*100;
        }
    },
    mounted(){
        this.days = localStorage.getItem('loggedDays')
        this.passed = localStorage.getItem('winDays')
    }
  }
  
  </script>

  <style>
#first{
    width:100vw;
    background: #DCD7C9;
    margin-top:2vh;
    display: grid;
    grid-template-columns:repeat(2,50vw);
}
#col11{
    width:50vw;
    padding-left:4vw;
    padding-top:0px;
    margin-top:0px;
}
.info{
    margin-top:5px;
    margin-bottom:10px;
}
.info h3{
font-family: 'Roboto';
font-size:28px;
color:rgba(0, 0, 0, 0.911);
font-weight: 400;
margin-bottom:2px;
}
.info h4{
    font-family: 'Roboto';
font-size:21px;
color:rgba(0, 0, 0, 0.611);
font-weight: 350;
margin-bottom:2px;
margin-top:5px;
}
#col12{
    padding-left:7.5vw;
    padding-top:0.3vh;
}
#prog1{
    margin-top:9px;
    margin-right:10px;
}
#num11{
    height:98px;
    overflow:hidden;
    width:70px;
    margin-top:-12.78vh;
    margin-right:9vw;
    float:right;
}
#num11 h3{
    padding:0px;
    margin-top:4px;
    margin-bottom:4px;
    color:#2C3639
}
#day1{
    font-family: 'Times New Roman', Times, serif;
    font-size:34px;
    font-weight: 400;
    color:rgba(0, 0, 0, 0.91);
    margin-left:7px;
}
#mid{ 
    font-family: 'Times New Roman', Times, serif;
font-size:16px;
font-weight: 350;
color:rgba(0, 0, 0, 0.61);
margin-left:4px;

}

h3{
color:#A27B5C;
}
h4{
color:#2C3639;
}
</style>