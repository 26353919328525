<template>
    <div id="container">
        <intro-comp></intro-comp>
        <div id="navigate">
            <h3 :class="Active == 0? 'active':'passive'" @click="redirect(0)">Embarrass</h3>
            <h3 :class="Active == 1? 'active':'passive'" @click="redirect(1)">Goals</h3>
            <h3 :class="Active == 2? 'active':'passive'" @click="redirect(2)">Achievements</h3>
        </div>
       <component :is="Component"/>
        <footer-comp></footer-comp>
    </div>
</template>

<script>
import IntroComp from '../components/general/intro-comp.vue'
import FooterComp from '../components/general/bottom-nam.vue'
import InfoComp1 from '../components/user-data/info-comp1.vue'
import InfoComp2 from '../components/user-data/info-comp2.vue'
import InfoComp3 from '../components/user-data/info-comp3.vue'
export default {
    components:{IntroComp,FooterComp,InfoComp1,InfoComp2,InfoComp3},
    data(){
        return{
            active:0,
            comp:'InfoComp1'
        }
    },
    computed:{
      Active(){
        return this.active;
      },
      Component(){
        return this.comp;
      }
    },
    methods:{
        redirect(val){
            if(val==0){
                this.active=0;
                this.comp = 'InfoComp1'

            }
            else if(val==1){
                this.active=1;
                this.comp = 'InfoComp2'
            }
            else if(val==2){
                this.active=2;
                this.comp = 'InfoComp3'
            }
        }
    },
    mounted(){
        this.$store.commit('setWelcome',{value:'Apne bare mei khuch batao!!'})
            this.$store.commit('setCurrPage',{value:0})
            
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Sofia');
#container{
    width: 100vw;
    height:fit-content;
    min-height: 100vh;
    overflow-x:hidden;
    background: #2C3639;
}
#navigate{
    display: grid;
    width:90vw;
    margin-left:7vw;
    grid-template-columns: repeat(3,auto);
}
#navigate h3{
    font-family: 'Sofia';
  
}
.active{
    color:#A27B5C;
    font-weight: 350;
    font-size:23px;
}
.passive{
    color:#DCD7C9;
    font-weight: 200;
    font-size:21px;
}

</style>