<template>
    <div id="bottom">
        <div id="col1" class="icon">
            <img :src="require('/src/assets/buttons/home-active.png')" alt="home" @click="redirect('aaj',0)" v-if="Active == 0" :class="Active==0?'active':'passive'">
            <img :src="require('/src/assets/buttons/home-normal.png')" alt="home" @click="redirect('aaj',0)" v-else class="passive">
        </div>
        <div id="col2" class="icon">
            <img :src="require('/src/assets/buttons/info-active.png')" alt="gaaali" @click="redirect('info',1)" v-if="Active == 1" :class="Active==1?'active':'passive'">
            <img :src="require('/src/assets/buttons/info-normal.png')" alt="gaaali" @click="redirect('info',1)" v-else class="passive">
        </div>
        <div id="col3" class="icon">
            <img :src="require('/src/assets/buttons/add-active.png')" alt="pyaar" @click="redirect('kal',2)" v-if="Active == 2" :class="Active==2?'active':'passive'">
            <img :src="require('/src/assets/buttons/add-normal.png')" alt="pyaar" @click="redirect('kal',2)" v-else class="passive">
        </div>
        <div id="col4" class="icon">
            <img :src="require('/src/assets/buttons/track-active.png')" alt="goals" @click="redirect('itihas',3)" v-if="Active == 3" :class="Active==3?'active':'passive'">
            <img :src="require('/src/assets/buttons/track-normal.png')" alt="goals" @click="redirect('itihas',3)" v-else class="passive">
        </div>
        <div id="col5" class="icon">
            <img :src="require('/src/assets/buttons/notification-active.png')" alt="notification" @click="redirect('notifications',4)" v-if="Active == 4" :class="Active==4?'active':'passive'">
            <img :src="require('/src/assets/buttons/notification-normal.png')" alt="notification" @click="redirect('notifications',4)" v-else class="passive">
        </div>
    </div>
</template>

<script>
export default {
    computed:{
        Active(){
            return this.$store.getters['getActivePage']
        }
    },
    methods:{
        redirect(url,index){
            this.$store.commit('setActivePage',{value:index})
            this.$router.push({name:url})
            }
    },
    mounted(){
        this.active=localStorage.getItem('active')
        
    }
}
</script>

<style scoped>
#bottom{
   width: 100vw;
   height:8vh;
   background:#A27B5C;
   position: fixed;
   bottom:0px;
   padding-left:2vw;
   padding-right:2vw;
   display: grid;
   grid-template-columns: repeat(5,19vw);
}
.icon{
    margin-top:13px;
    margin-left:20px;
}
.passive{
    height:30px;
    width:30px;
}
.active{
    height:40px;
    width:40px;
    margin-left:0px;
}
</style>