<template>
    <div id="container">
        <intro-comp></intro-comp>
        <div class="tasks" v-if="Tasks.length>0">
            <task-comp v-for="task,index in Tasks" :key="index"
            :title="task.title"
            :description="task.description"
            :subtasks="task.subtask"
            :color="index"
            ></task-comp>
            <div id="push" @click="hustle">PUSH</div>
        </div>
        <free-hai :kab="kal" v-else></free-hai>
        
        <bottom-nav></bottom-nav>
    </div>
</template>
<script>
import IntroComp from '../components/general/intro-comp.vue'
import FreeHai from '../components/general/free-hai.vue'
import BottomNav from '../components/general/bottom-nam.vue'
import TaskComp from '../components/tasks/task-kal.vue'
export default ({
    components:{IntroComp,FreeHai,BottomNav,TaskComp},
    mounted(){
        this.$store.commit('setWelcome',{value:'Hello abhi lets plan for tomorrow'})
        this.$store.commit('setCurrPage',{value:1})
        
        if(!localStorage.getItem('tomorrowTasks')){
            this.tasks=[]
        }
        else{
            this.tasks=JSON.parse(localStorage.getItem('tomorrowTasks'))
        }
    },
    data(){
        return{
            tasks:[],
            
        }
    },
    computed:{
        Tasks(){
            return this.tasks;
        }
    },
    methods:{
        hustle(){
            this.logDay();
            let tasks = JSON.parse(localStorage.getItem('tomorrowTasks'))
            localStorage.setItem('todTasks',tasks.length)
            let prize = this.award();
            console.log(prize)
            const now = Date()
                let date = now.toString().split(' ')
                date = date[0]+ ' ' + date[1] + ' ' + date[2]
           
            if(prize == false){
                this.streak(1);
                localStorage.setItem('pop',1)
                localStorage.setItem('popVal','award')
                this.updateDays(true)
               if(!localStorage.getItem('notifications')){
                    localStorage.setItem('notifications',JSON.stringify([{'msg':`Shame you failed to conquer the day!!`,'date':date,'type':'failure'}]))
                }
            else{
                let notifications = JSON.parse(localStorage.getItem('notifications'))
                notifications.push({'msg':`Yay you conquered the day!!`,'date':date,'type':'failure'})
                localStorage.setItem('notifications',JSON.stringify(notifications))
            }
            }
            else{
                this.streak(0)
                localStorage.setItem('pop',1)
                localStorage.setItem('popVal','punish')
                this.updateDays(false)
                if(!localStorage.getItem('notifications')){
                    localStorage.setItem('notifications',JSON.stringify([{'msg':`Yay you conquered the day!!`,'date':date,'type':'success'}]))
                }
            else{
                let notifications = JSON.parse(localStorage.getItem('notifications'))
                notifications.push({'msg':`Shame you failed to conquer the day!!`,'date':date,'type':'success'})
                localStorage.setItem('notifications',JSON.stringify(notifications))
            }
            }
            localStorage.setItem('todayTasks',JSON.stringify(tasks))
            localStorage.setItem('tomorrowTasks',JSON.stringify([]))
            this.tasks=[]
            localStorage.setItem('nav','aaj')
            this.$router.push({name:'aaj'})
        },
        award(){
            if(!localStorage.getItem('todayTasks'))
            return true;
            let tasks = JSON.parse(localStorage.getItem('todayTasks'))
            if(tasks.length==0) return true;
            else return false;
        },
        updateDays(val){
            if(!localStorage.getItem('loggedDays'))
            localStorage.setItem('loggedDays',1)
            else{
                let days = JSON.parse(localStorage.getItem('loggedDays'))
                localStorage.setItem('loggedDays',days+1)
            }
            if(val == true){
                if(!localStorage.getItem('winDays'))
                localStorage.setItem('winDays',1)
                else{
                    let days = JSON.parse(localStorage.getItem('winDays'))
                    localStorage.setItem('winDays',days+1)
                }
            }
        },
        logDay(){
            let logged = JSON.parse(localStorage.getItem('todTasks'))
            let left;
            if(!localStorage.getItem('todayTasks')) left=0;
            else left = JSON.parse(localStorage.getItem('todayTasks')).length
            let date = new Date();
            let day = date.getDay();
            console.log('this is the left value',left)
            console.log('this is the today tasks',logged)
            let percent;
            if(left==0){
                percent=100;
            }
            else{
                percent=((logged-left)/logged)*100;
                
            }
            percent = Math.floor(percent)
            if(day==0) localStorage.setItem('mon',percent)
            if(day==1) localStorage.setItem('tue',percent)
            if(day==2) localStorage.setItem('wed',percent)
            if(day==3) localStorage.setItem('thu',percent)
            if(day==4) localStorage.setItem('fri',percent)
            if(day==5) localStorage.setItem('sat',percent)
            if(day==6) localStorage.setItem('sun',percent)
            const now = Date()
            let tareekh = now.toString().split(' ')
            tareekh = tareekh[2] + ' ' + tareekh[1]
            let logs = JSON.parse(localStorage.getItem('logs'))
            logs.push({'date':tareekh,'percent':percent})
            localStorage.setItem('logs',JSON.stringify(logs))
        },
        streak(val){
            if(!localStorage.getItem('streak'))
            localStorage.setItem('streak',0);
            else
            {  
                if(val==0) localStorage.setItem('streak',0)
                else{
                    let streak = JSON.parse(localStorage.getItem('streak'))
                localStorage.setItem('streak',streak+1)
                }
            }
        }
    }
   
})
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Sofia');
#container{
    width:100vw;
    height:fit-content;
    background: #2C3639;
    min-height: 100vh;
    height:fit-content;
    overflow-x: hidden;
}
.tasks{
    width:100vw;
    margin-top:35px;
    margin-bottom:22vh;
}
#push{
    width:50vw;
height:50px;
border-radius: 10px;
background:#A27B5C;
border:1px solid #A27B5C;
color:#2C3639;
text-align: center;
font-size:24px;
font-weight:500;
line-height: 50px;
font-family: 'Sofia';
margin-left:25vw;
margin-top:16px;
}
</style>