<template>
    <div id="intro">
        <img :src="require('/src/assets/cappy-medium.png')" alt="cappybara" @click="back">
        <h1>{{ kya }}</h1>
     </div>
     <div id="info">
         <h1>{{ date }}</h1>
         <img :src="require('/src/assets/buttons/add-task.png')" alt="add" @click="formOpen">
     </div>
</template>
<script>
import router from '@/router'
export default({
    computed:{
        date(){
            const now = new Date()
            let day = now.toUTCString().split(',')[0].toString()
            let type = this.$store.getters['getCurrPage']
            if(type == 0){
            if(day=='Mon'){ return "Monday" }
            if(day=='Tue'){ return "Tuesday" }  
            if(day=='Wed'){ return "Wednesay" }  
            if(day=='Thu'){ return "Thursday" }  
            if(day=='Fri'){ return "Friday" }  
            if(day=='Sat'){ return "Saturday" }  
            if(day=='Sun'){ return "Sunday" }    
            else{return 'Thursday'}   
           }
           else{
            if(day=='Mon'){ return "Tuesday" }
            if(day=='Tue'){ return "Wednesday" }  
            if(day=='Wed'){ return "Thursday" }  
            if(day=='Thu'){ return "Friday" }  
            if(day=='Fri'){ return "Saturday" }  
            if(day=='Sat'){ return "Sunday" }  
            if(day=='Sun'){ return "Monday" }    
            else{return 'Thursday'}   
           }
        },
        kya(){
            return this.$store.getters['getWelcome']
        },
    },
    methods:{
        formOpen(){
            router.push({name:'form'})
        },
        back(){
            this.$router.back();
        }
    }
})
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Sofia');
#container::-webkit-scrollbar{
    display: none;
}
#intro{
   display: flex;
   flex-direction: row;
}
#intro img{
width:80px;
height:70px;
margin-top:14px;
margin-left:10px;
}
#intro h1{
margin-left:4px;
font-size:22px;
color:#DCD7C9;
font-weight:400;
font-family:'Sofia';
margin-top:14px;
}
#info h1{
    float:left;
    margin-left:30px;
    font-size:21px;
    color:#DCD7C9;
    font-weight: 400;
    font-family:'Sofia';
    margin-top:-2px;
}
#info{
    display: flex;
    flex-direction: row;
}
#info img{
margin-left:83vw;
bottom:20vw;
position:fixed;
}
</style>