<template>
    <div id="secondSec">
        <div id="row21" v-if="Streak>0">
            <h3>You are one a </h3>
            <h2>{{ Streak }} Days streak</h2>
        </div>
        <div id="row22">
              <div id="sec1" style="margin-top:14px;">
                <div>
                    <green-circle v-if="Monday == 100"></green-circle>
                    <red-circle v-else :percent="Monday"></red-circle>
                    <h4 style="margin-top:0px;color:#2C3639;font-weight:350;font-family:'Roboto';margin-left:7px;">Mon</h4>
                </div>
                <div>
                    <green-circle v-if="Tuesday == 100"></green-circle>
                    <red-circle v-else :percent="Tuesday"></red-circle>
                    <h4 style="margin-top:0px;color:#2C3639;font-weight:350;font-family:'Roboto';margin-left:7px;">Tue</h4>
                </div>
                <div>
                    <green-circle v-if="Wednesday == 100"></green-circle>
                    <red-circle v-else :percent="Wednesday"></red-circle>
                    <h4 style="margin-top:0px;color:#2C3639;font-weight:350;font-family:'Roboto';margin-left:7px;">Wed</h4>
                </div>
                <div>
                    <green-circle v-if="Thursday == 100"></green-circle>
                    <red-circle v-else :percent="Thursday"></red-circle>
                    <h4 style="margin-top:0px;color:#2C3639;font-weight:350;font-family:'Roboto';margin-left:7px;">Thu</h4>
                </div>
                <div>
                    <green-circle v-if="Friday == 100"></green-circle>
                    <red-circle v-else :percent="Friday"></red-circle>
                    <h4 style="margin-top:0px;color:#2C3639;font-weight:350;font-family:'Roboto';margin-left:7px;">Fri</h4>
                </div>
                <div>
                    <green-circle v-if="Saturday == 100"></green-circle>
                    <red-circle v-else :percent="Saturday"></red-circle>
                    <h4 style="margin-top:0px;color:#2C3639;font-weight:350;font-family:'Roboto';margin-left:7px;">Sat</h4>
                </div>
                <div>
                    <green-circle v-if="Sunday == 100"></green-circle>
                    <red-circle v-else :percent="Sunday"></red-circle>
                    <h4 style="margin-top:0px;color:#2C3639;font-weight:350;font-family:'Roboto';margin-left:7px;">Sun</h4>
                </div>
              </div>
              <div id="sec2">
                <div id="divider"></div>
                <div>
                    <img :src="require('/src/assets/flame.png')" alt="fire" style="margin-top:4px;">
                    <h3 style="color:#DCD7C9;font-weight:450;font-size:28px;margin-top:-90px;text-align:centre;margin-left:16px;" v-if="Streak>0">{{ Streak }} Days</h3>
                </div>
              </div>
        </div>
    </div>
</template>

<script>
import GreenCircle from './green-circle.vue'
import RedCircle from './red-circle.vue'
export default {
    components:{GreenCircle,RedCircle},
    data(){
        return{
            monday:0,
            tuesday:0,
            wednesday:0,
            thursday:0,
            friday:0,
            saturday:0,
            sunday:0,
            streak:0
        }
    },
    computed:{
      Monday(){return this.monday;},
      Tuesday(){return this.tuesday},
      Wednesday(){return this.wednesday},
      Thursday(){return this.thursday},
      Friday(){return this.friday},
      Saturday(){return this.saturday},
      Sunday(){return this.sunday},
      Streak(){return this.streak}
    },
    mounted(){
        this.monday=JSON.parse(localStorage.getItem('mon'));
        this.tuesday=JSON.parse(localStorage.getItem('tue'));
        this.wednesday=JSON.parse(localStorage.getItem('wed'));
        this.thursday=JSON.parse(localStorage.getItem('thu'));
        this.friday=JSON.parse(localStorage.getItem('fri'));
        this.saturday=JSON.parse(localStorage.getItem('sat'));
        this.sunday=JSON.parse(localStorage.getItem('sun'));
        if(!localStorage.getItem('streak')) this.streak=0;
        else this.streak = JSON.parse(localStorage.getItem('streak'))
    }
}
</script>

<style scoped>
#secondSec{
    width:100vw;
    background: #DCD7C9;
    margin-top:2vh;
    margin-top:-1px;
}
#row21{
    width:100vw;
    padding-top:5px;
    margin-top:5px;
    padding-left:3.5vw;
}
#row21 h3{
    font-family: 'Roboto';
    color:#2C3639;
    font-weight:350;
    font-size:19px;
    margin-top:17px;
    margin-bottom:2px;
}
#row21 h2{
    font-family: 'Roboto';
    color:#2C3639;
    font-weight:400;
    font-size:24px;
    margin-top:2px;
    margin-bottom:7px;
}
#row22{
    width:100vw;
    margin-top:2vh;
    display: grid;
    grid-template-columns: 60vw 40vw;

}
#divider{
    width:1px;
    background:rgba(0,0,0,0.36);
    height:90%;
    margin-top:5%;
}
#sec2{
    width:40vw;
    display: grid;
    grid-template-columns: 10px auto;
    padding-left:5px;
}
#sec1{
    width:60vw;
    display: grid;
    grid-template-columns: repeat(4,auto);
    padding-left:6px;
}
#flame{
    margin-top:10px;
}
</style>