<template>
    <div id="container">
        <intro-comp></intro-comp>
         <first-comp></first-comp>
         <second-comp></second-comp>
         <third-comp></third-comp>
         <footer-comp></footer-comp>
    </div>
</template>

<script>
import FirstComp from '../components/itihas/first-comp.vue'
import SecondComp from '../components/itihas/second-comp.vue'
import ThirdComp from '../components/itihas/third-comp.vue'
import FooterComp from '../components/general/bottom-nam.vue'
import IntroComp from '../components/general/intro-comp.vue'
export default {
    components:{FirstComp,SecondComp,ThirdComp,FooterComp,IntroComp},
    mounted(){
        this.$store.commit('setWelcome',{value:'Hey champ this is your record!'})
            this.$store.commit('setCurrPage',{value:0})
    }
}
</script>

<style scoped>
#container{
    top:0px;
    left:0px;
    width:100vw;
    overflow-x:hidden;
    overflow-y:auto;
    background: #2C3639;
    overflow-x:hidden;
}
#container::-webkit-scrollbar{
    display:none;
}
#intro{
    display: flex;
    flex-direction: row;
 }
#intro img{
    margin-top:14px;
    margin-left:10px;
    }
#intro h1{
    margin-left:9px;
    font-size:22px;
    color:rgba(0, 0, 0, 0.54);
    font-weight:400;
    font-family:Imprint MT Shadow;
    margin-top:24px;
    }
</style>