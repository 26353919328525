<template>
    <div id="modal">
        <img :src="require('/src/assets/cappy-medium.png')" alt="cappy">
        <h3 style="margin-left:13vw;">{{ Msg }}</h3>
        <div id="msg">
            <h3>{{ Title }}</h3>
            <h4>{{ Description }}</h4>
        </div>
        <div id="modButton" @click="disapper">{{ btMsg }}</div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            msg:"",
            title:"",
            description:"",
            button:''
        }
    },
    computed:{
        Msg(){
return this.msg
        },
        Title(){return this.title},
        Description(){return this.description},
        btMsg(){return this.button}
    },
    mounted(){
        if(localStorage.getItem('popVal')=='award'){
            this.msg = 'Tumse na ho paege beta';
    this.button = 'mehnat karle'
        
         if(!localStorage.getItem('galis')){
            this.title = 'Bhai thodi sharam karle';
            this.description='Go and add some embarassing moments to remind you in gali section'
        return
    }
         let msgs = JSON.parse(localStorage.getItem('galis'))
         if(msgs.length==0){
            this.title = 'Bhai thodi sharam karle';
            this.description='Go and add some embarassing moments to remind you in gali section'
         }
         else{
            let gali = msgs[(Math.floor(Math.random() * msgs.length))]
         this.title=gali.title;
         this.description=gali.description;
         }

}
if(localStorage.getItem('popVal')=='punish'){
    this.msg = 'Bete mauj kardi!!';
         this.button='keep hustling'
    if(!localStorage.getItem('pyaars')){
        this.title = 'Mauj Kardi bete!!'
            this.description='Just do one more thing go and fill some of your great moments to remind on success!'
        return
    }
    let msgs = JSON.parse(localStorage.getItem('pyaars'))
    if(msgs.length==0){
            this.title = 'Mauj Kardi bete!!'
            this.description='Just do one more thing go and fill some of your great moments to remind on success!'
         }
    else{
        let pyaar = msgs[(Math.floor(Math.random() * msgs.length))]
         this.title=pyaar.title;
         this.description=pyaar.description;
    }
}
    },
    methods:{
        disapper(){
            this.$emit('modalState',0)
            localStorage.setItem('pop',0)
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Sofia');
#modal{
    width:90vw;
    max-height:50vh;
    height: fit-content;
    padding:4px;
    margin-left: 5vw;
    box-shadow:2px 2px 4px rgba(0, 0, 0, 0.36);
    border-radius:10px;
    background: #DCD7C9;
    margin-top:50px;
    overflow-x: hidden;
    overflow-y: auto;
}
#modal::-webkit-scrollbar{
    display:none;
}
#modal img{
    float:left;
    margin-left:2.5vw;
    margin-top:10px;
    width:35px;
    height:35px;
}
#modButton{
    width:50vw;
    height:50px;
    border-radius: 20px;
box-shadow:2px 2px 4px rgba(0, 0, 0, 0.474);
background:#A27B5C;
border:1px solid #3F4E4F;
color:#2C3639;
text-align: center;
font-size:20px;
font-weight: 450;
line-height: 50px;
font-family: 'Sofia';
margin-left:20vw;
margin-top:10px;
}
#msg{
    padding-left:4px;
    padding-right:4px;
}
#msg h3{
    color:black;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size:24px;
    font-weight: 450;
    margin-bottom:1px;
}
#msg h4{
    color:rgba(0, 0, 0, 0.893);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size:19px;
    font-weight: 350;

    margin-top:2px;
}
</style>