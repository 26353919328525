<template>
    <circle-progress :percent="100"
    :size="45"
    :border-width="5"
    :border-bg-width="5"
    :show-percent="true"
    :is-gradient="true"
        :gradient="{
          angle: 90,
          startColor: '#13A827',
          stopColor: '#13A827'
      }"
      id="green"
    />
</template>

<script>
 import "vue3-circle-progress/dist/circle-progress.css";
  import CircleProgress from "vue3-circle-progress";
export default {
    components: {CircleProgress},
}
</script>

<style scoped>

</style>