<template>
   <div id="container">
    <audio autoplay  ref="audio">
      <source :src="require('../assets/audio/cappy.mp3')" type="audio/mpeg">
      Your browser does not support the audio tag.
    </audio>
     <h1 class="text">Hello I am cappy</h1>
     <img :src="require('../assets/cappy-large.png')" alt="Cappy" id="cappy">
     <h1 class="text">I will help you in tracking your daily tasks</h1>
     <ul>
      <li>Complete all task and get reward</li>
      <li>fail to do so will punish you</li>
     </ul>
     <div id="button" @click="redirect">
      Lets Start
     </div>
   </div>
    
</template>

<script>
import router from "@/router"


export default({
    methods:{
      redirect(){
      router.push({name:'aaj'})
        }
    },
    mounted(){
      this.$refs.audio.play();
    }
})
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Sofia');
#container{
    top:0px;
    left:0px;
    width:100vw;
    background: #2C3639;
    padding-top:10vh;
    overflow: hidden;
    height: 90vh;

}
.text{
  text-align: center;
  font-weight: 450;
  color:#DCD7C9;
  font-size:32px;
  margin-top:10px;
  margin-bottom:10px;
  font-family: 'Sofia';
}
#cappy{
  margin-top:10px;
  margin-bottom:10px;
  margin-left:25vw;
}
ul{
  font-weight: 450;
  color:#DCD7C9;
  font-size:24px;
  margin-top:10px;
  margin-bottom:10px;
  font-family: 'Sofia';
}
li{
  margin-left:3vw;
  font-family: 'Sofia';
} 
#button{
  width:50vw;
  margin-left:25vw;
  margin-right:25vw;
  border-radius:10px;
  height:62px;
  font-family: 'Sofia';
  line-height: 62px;
  text-align: center;
  background: #A27B5C;
  color:#2C3639;
  font-size:26px;
  position:fixed;
  bottom:8vh;
}
</style>