<template>
    <div class="container">
        <div id="intro">
            <img :src="require('../assets/cappy-medium.png')" alt="cappybara" @click="back">
            <h1>Yeah boii!! here's some news for you!!</h1>
         </div>
         <div id="info">
             <h1>{{ date }}</h1>
         </div>
         <div id="notifications" v-if="Notifications.length>0">
            <div  v-for="notification,index in Notifications" :key="index">
                <div id="notif">
                    <img class="rune" :src="require('../assets/icons/failure.png')" alt="" v-if="notification.type=='failure'">
                    <img class="rune" :src="require('../assets/icons/success.png')" alt="" v-if="notification.type=='success'">
                    <img class="rune" :src="require('../assets/icons/micromanage.png')" alt="" v-if="notification.type=='micro'">
                    <img class="rune" :src="require('../assets/icons/overwork.png')" alt="" v-if="notification.type=='overplan'">
                    <h2>{{ notification.msg }}</h2>
                    <h3>{{ notification.date }}</h3>
                </div>
            </div>
         </div>
         <div v-else id="else">
            <h3>No new notifications</h3>
            <img :src="require('../assets/cappy-medium.png')" alt="cappybara">
         </div>
         <footer-comp></footer-comp>
    </div>
</template>

<script>
import FooterComp from '../components/general/bottom-nam.vue'

export default {
    /*
    THis is the code of notifications
    failure=>failed to complete task
    success=>completed all the task
    micro=>tried to micromanage something
    overplan=>tried to overplan the day
    */
   components:{FooterComp},
    computed:{
        date(){
            const now = new Date()
            let day = now.toUTCString().split(',')[0].toString()
            if(day=='Mon'){ return "Monday" }
            if(day=='Tue'){ return "Tuesday" }  
            if(day=='Wed'){ return "Wednesay" }  
            if(day=='Thu'){ return "Thursday" }  
            if(day=='Fri'){ return "Friday" }  
            if(day=='Sat'){ return "Saturday" }  
            if(day=='Sun'){ return "Sunday" }    
            else{return 'Thursday'}   
        },
        Notifications(){
            return this.notifications;
        }
    },
    data(){
        return{
            notifications:[]
        }
    },
    methods:{
        back(){
            this.$router.back();
        }
    },
    mounted(){
        try{
            this.$store.commit('setWelcome',{value:'Thoda apne baare mei jaanlo'})
            this.$store.commit('setCurrPage',{value:0})
            if(!localStorage.getItem('notifications'))
        this.notifications=[]
        if(JSON.parse(localStorage.getItem('notifications')).length==0)
        this.notifications=[]
       this.notifications=JSON.parse(localStorage.getItem('notifications'))
        }
        catch(err){
            this.notifications=[]
        }
    }
}
</script>

<style scoped>
.container{
    width:100vw;
    overflow-x:hidden;
    min-height: 100vh;
    height:fit-content;
    background: #2C3639;
}
#form{
    top:0px;
    left:0px;
    width:100vw;
    height: fit-content;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}
#form::-webkit-scrollbar{
    display: none;
}
#intro{
    display: flex;
    flex-direction: row;
 }
 #intro img{
 width:80px;
 height:70px;
 margin-top:14px;
 margin-left:10px;
 }
 #intro h1{
 margin-left:4px;
 font-size:22px;
 color:#DCD7C9;
 font-weight:400;
 font-family:'Sofia';
 margin-top:14px;
 }
 #info h1{
     float:left;
     margin-left:30px;
     font-size:21px;
     color:#DCD7C9;
     font-weight: 400;
     font-family:'Sofia';
     margin-top:-2px;
 }
 #notifications{
    margin-top:2vh;
    width:100vw;
 }
 #notif{
    width:100vw;
    overflow-x: hidden;
    margin-left:1vw;
    background:#3F4E4F;
    margin-top:2.5px;
    margin-bottom:2.5px;
    padding-top:0px;
    padding-bottom:0px;
 }
 #notif h2{
    padding-top:0px;
    margin-top:8px;
    margin-left:6px;
    font-size:18px;
    font-weight:400;
    color:#DCD7C9;
    font-family:Georgia, 'Times New Roman', Times, serif;
    margin-bottom:2px;
 }
 #notif h3{
    padding-top:0px;
    margin-top:8px;
    float:right;
    margin-right:20px;
    margin-bottom:3px;
    font-size:18px;
    font-weight:350;
    color:#A27B5C;
    font-family:Georgia, 'Times New Roman', Times, serif;
 }
 #else{
    margin-top:9vh
 }
 #else h3{
text-align:center;
font-weight: 400;
color:#DCD7C9;
font-size:21px;
font-family:'Sofia';
 }
 #else img{
    margin-left:35vw;
    margin-top:4vh;
    animation: rotation 2s linear infinite;
 }
 @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  .rune{
    float:left;
    margin-right:15px;
    margin-top:10px;
  }
</style>>