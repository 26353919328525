<template>
    <div id="container">
        <intro-comp :from='aaj'></intro-comp>
<pop-comp v-if="Pop == 1" @modalState="closeModal"></pop-comp>
<div v-else>
    <div class="tasks" v-if="Tasks.length>0">
        <task-comp v-for="task,index in Tasks" :key="index"
        :title="task.title"
        :description="task.description"
        :subtasks="task.subtask"
        :color="index"
        ></task-comp>
    </div>
    <free-hai :kab="aaj" v-else></free-hai>
</div>
        <bottom-nav></bottom-nav>

    </div>
</template>
<script>
import IntroComp from '../components/general/intro-comp.vue'
import FreeHai from '../components/general/free-hai.vue'
import BottomNav from '../components/general/bottom-nam.vue'
import TaskComp from '../components/tasks/task-aaj.vue'
import PopComp from '../components/tasks/pop-comp.vue'
export default ({
    components:{IntroComp,FreeHai,BottomNav,TaskComp,PopComp},
    async mounted(){
        await this.$store.commit('setWelcome',{value:'Hello abhi lets plan for today'})
        await this.$store.commit('setCurrPage',{value:0})
        this.pop=localStorage.getItem('pop')
        if(!localStorage.getItem('todayTasks')){
            this.tasks=[]
        }
        else{
            this.tasks=JSON.parse(localStorage.getItem('todayTasks'))
        }
    },
    data(){
        return{
            tasks:[],
            pop:0
        }
    },
    computed:{
        Tasks(){
            return this.tasks;
        },
        Pop(){
            return this.pop;
        },

    },
    methods:{
        closeModal(){
            this.pop=0;
        }
    }
})
</script>
<style scoped>
#container{
    width:100vw;
    height:fit-content;
    min-height: 100vh;
    overflow-x: hidden;
    background: #2C3639;
}
.tasks{
    width:100vw;
    margin-top:35px;
    margin-bottom:22vh;
}
</style>